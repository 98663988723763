// MealDetailDialog.tsx

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Chip,
} from "@mui/material";

import { MealHistory } from "../types/mealTypes"; // 型をインポート

interface MealDetailDialogProps {
  open: boolean;
  onClose: () => void;
  meal: MealHistory | null;
}

const MealDetailDialog: React.FC<MealDetailDialogProps> = ({
  open,
  onClose,
  meal,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.2rem",
          mt: 2,
        }}
      >
        {meal?.meal_name}
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#ccc",
            mt: 1,
          }}
        />
      </DialogTitle>

      <DialogContent>
        {/* 画像を表示し、高さを固定 */}
        {meal?.image_url && (
          <Box
            component="img"
            src={meal.image_url}
            alt="Meal Image"
            sx={{
              width: "100%",
              height: "200px",
              objectFit: "cover",
              borderRadius: "8px",
              mb: 2,
            }}
          />
        )}

        {/* ジャンル */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 0.5 }}>
          ジャンル
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
          {meal?.meal_genre?.split(",").map((genre, index) => (
            <Chip
              key={index}
              label={genre.trim()}
              sx={{
                backgroundColor: "rgba(99, 180, 255, 0.1)", // 評価履歴一覧と同じ背景色
                color: "rgba(99, 180, 255)", // 評価履歴一覧と同じ文字色
                "&:hover": {
                  backgroundColor: "rgba(99, 180, 255, 0.4)", // 評価履歴一覧と同じホバー色
                },
              }}
            />
          ))}
        </Box>
        {/* 推定カロリー */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 0.5 }}>
          推定カロリー
        </Typography>
        <Typography color="textSecondary" sx={{ mb: 2 }}>
          {meal?.meal_calorie} kcal
        </Typography>

        {/* 解析理由 */}
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 0.5 }}>
          解析理由
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f0f0", // グレーの背景色
            borderRadius: "8px", // 角丸
            padding: "12px 12px", // 内側の余白
            mt: 1, // 上部マージンで他の要素からスペースを追加
          }}
        >
          <Typography color="textSecondary">{meal?.meal_reason}</Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            border: "1px solid #ccc",
            backgroundColor: "white",
            color: "#333",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MealDetailDialog;
