import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Restaurant, Star } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const TabBar: React.FC = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(
    window.location.pathname === "/meal-history" ? 0 : 1
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      navigate("/meal-history");
    } else if (newValue === 1) {
      navigate("/rating-list");
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        padding: "2.4rem 0",
        zIndex: 2,
      }}
    >
      <BottomNavigationAction
        label="食事履歴"
        icon={<Restaurant />}
        sx={{
          minWidth: 0,
          "& .MuiBottomNavigationAction-label": {
            fontSize: "1rem",
            backgroundColor: value === 0 ? "rgba(99, 180, 255, 0.1)" : "none",
            borderRadius: value === 0 ? "12px" : "0px", // 選択中のみ角丸背景色
            padding: "0.2rem 0.4rem", // 選択中のみパディング
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.4rem",
            color: value === 0 ? "#63B4FF" : "8696BB", // 選択中はアイコンの色変更
          },
          color: value === 0 ? "#63B4FF" : "8696BB", // 選択中は文字色変更
        }}
      />
      <BottomNavigationAction
        label="評価履歴"
        icon={<Star />}
        sx={{
          minWidth: 0,
          "& .MuiBottomNavigationAction-label": {
            fontSize: "1rem",
            backgroundColor: value === 1 ? "rgba(99, 180, 255, 0.1)" : "none",
            borderRadius: value === 1 ? "12px" : "0px", // 選択中のみ角丸背景色
            padding: "0.2rem 0.4rem", // 選択中のみパディング
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.4rem",
            color: value === 1 ? "#63B4FF" : "8696BB", // 選択中はアイコンの色変更
          },
          color: value === 1 ? "#63B4FF" : "8696BB", // 選択中は文字色変更
        }}
      />
    </BottomNavigation>
  );
};

export default TabBar;
