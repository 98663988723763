import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slider,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Chip,
  TextField,
  CircularProgress,
} from "@mui/material";
import { addRating } from "../services/ratingService";
import { useAuth } from "../contexts/AuthContext";

interface GenreSelectionDialogProps {
  open: boolean;
  onClose: () => void;
}

// ジャンルデータ（例としてラーメンの画像を全て使用）
const genres = [
  {
    name: "中華料理",
    image: "/images/chinese_cuisine.jpeg",
    description: "麻婆豆腐、餃子、チャーハン",
  },
  {
    name: "ハンバーガー",
    image: "/images/hamburger.jpeg",
    description: "ハンバーガー、チーズバーガー",
  },
  {
    name: "イタリア料理",
    image: "/images/italian_cuisine.jpeg",
    description: "ピザ、パスタ、リゾット",
  },
  {
    name: "カレー",
    image: "/images/curry.jpeg",
    description: "カレーライス、ナンカレー",
  },
  {
    name: "居酒屋",
    image: "/images/izakaya.jpeg",
    description: "居酒屋、ダイニングバー、バー",
  },
  {
    name: "日本料理",
    image: "/images/japanese_cuisine.jpeg",
    description: "和食",
  },
  {
    name: "洋食",
    image: "/images/western_cuisine.jpeg",
    description: "オムライス、ハンバーグ、ビーフシチュー",
  },
  {
    name: "お好み焼き",
    image: "/images/okonomiyaki.jpeg",
    description: "お好み焼き、もんじゃ焼き",
  },
  {
    name: "ラーメン",
    image: "/images/ramen.jpeg",
    description: "ラーメン、つけ麺、油そば",
  },
  {
    name: "しゃぶしゃぶ",
    image: "/images/shabu_shabu.jpeg",
    description: "しゃぶしゃぶ、すき焼き",
  },
  {
    name: "そば",
    image: "/images/soba.jpeg",
    description: "そば、ざるそば",
  },
  {
    name: "ステーキ",
    image: "/images/steak.jpeg",
    description: "ステーキ、鉄板焼き",
  },
  {
    name: "寿司",
    image: "/images/sushi.jpeg",
    description: "寿司、回転寿司",
  },
  {
    name: "天ぷら",
    image: "/images/tempura.jpeg",
    description: "天ぷら、エビフライ",
  },
  {
    name: "うどん",
    image: "/images/udon.jpeg",
    description: "うどん、ざるうどん、カレーうどん",
  },
  {
    name: "焼肉",
    image: "/images/yakiniku.jpeg",
    description: "焼肉、ホルモン、ジンギスカン",
  },
  {
    name: "焼き鳥",
    image: "/images/yakitori.jpeg",
    description: "焼き鳥、つくね、鶏料理",
  },
  {
    name: "フランス料理",
    image: "/images/french_cuisine.jpeg",
    description: "オムレツ、クロワッサン",
  },
];

const GenreSelectionDialog: React.FC<GenreSelectionDialogProps> = ({
  open,
  onClose,
}) => {
  const { userId } = useAuth();
  const [hungerLevel, setHungerLevel] = useState<number>(2);
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [currentTime, setCurrentTime] = useState<string>(
    new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  ); // 日本時間を初期値に設定（ISOフォーマット）
  const isSaveDisabled = selectedGenres.length === 0;
  const [loading] = useState(false);

  const handleHungerChange = (event: Event, newValue: number | number[]) => {
    setHungerLevel(newValue as number);
  };

  const handleGenreClick = (genre: string) => {
    if (selectedGenres.includes(genre)) {
      setSelectedGenres(selectedGenres.filter((g) => g !== genre));
    } else {
      setSelectedGenres([...selectedGenres, genre]);
    }
  };

  const handleAdd = async () => {
    try {
      if (!userId) {
        throw new Error("ユーザーIDが取得できません");
      }

      const result = await addRating(
        hungerLevel,
        selectedGenres,
        currentTime,
        userId
      );
      if (result.success) {
        console.log("評価が追加されました");
        resetFields();
        onClose(); // ダイアログを閉じる
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("評価追加エラー:", error);
    }
  };

  const resetFields = () => {
    setHungerLevel(2); // 空腹度をリセット
    setSelectedGenres([]); // ジャンル選択をリセット
  };

  const handleCloseDialog = () => {
    resetFields();
    onClose();
  };

  const setOpen = (open: boolean) => {
    if (open) {
      handleClickOpen();
    } else {
      handleCloseDialog();
    }
  };

  const handleClickOpen = () => {
    setCurrentTime(
      new Date(new Date().getTime() + 9 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 16)
    );
    setHungerLevel(2);
    setSelectedGenres([]);
    setOpen(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
          height: "90vh",
          maxHeight: "90vh",
        },
      }}
      sx={{ maxWidth: "100%" }}
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "1.2rem",
          fontWeight: "bold",
          mt: 2,
        }}
      >
        評価を追加
        <Box
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "#ccc",
            marginTop: "8px",
            px: 2, // 左右の余白
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ pt: 2, pb: 1, pr: 2, pl: 2 }}>
        {/* 評価時間 */}
        <Typography gutterBottom sx={{ pl: 1, fontWeight: 600 }}>
          評価時間
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            type="datetime-local"
            value={currentTime}
            onChange={(e) => setCurrentTime(e.target.value)}
            sx={{ mb: 2, width: "90%" }} // Width set to 90%, but centered
          />
        </Box>
        {/* 空腹度選択 */}
        <Typography gutterBottom sx={{ pl: 1, fontWeight: 600 }}>
          空腹度
        </Typography>
        <Box display="flex" justifyContent="center">
          <Slider
            value={hungerLevel}
            onChange={handleHungerChange}
            step={1}
            marks={[
              { value: 1, label: "低" },
              { value: 2, label: "中" },
              { value: 3, label: "高" },
            ]}
            min={1}
            max={3}
            sx={{
              width: "80%",
              "& .MuiSlider-rail": {
                backgroundColor: "#ccc", // デフォルトの背景バーをグレーに
              },
              "& .MuiSlider-track": {
                backgroundColor: "rgba(99, 180, 255)", // 選択されている部分は水色
              },
              "& .MuiSlider-mark": {
                backgroundColor: "#ccc", // マークのデフォルト状態はグレー
                height: 10, // 点を少し大きく
                width: 10, // 点を少し大きく
                borderRadius: "50%", // 丸くする
              },
              "& .MuiSlider-markActive": {
                backgroundColor: "rgba(99, 180, 255)", // アクティブな点は水色
                height: 12, // アクティブな点を少し大きく
                width: 12, // アクティブな点を少し大きく
              },
              "& .MuiSlider-thumb": {
                width: 12,
                height: 12,
                transition: "transform 0.2s ease", // モダンな動きにする
                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "0px 0px 10px rgba(99, 180, 255, 0.6)", // アクティブ時の影
                },
              },
            }}
          />
        </Box>

        {/* 食べたいジャンル選択 */}
        <Typography gutterBottom sx={{ mt: 2, pl: 1, fontWeight: 600 }}>
          食べたいジャンル
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            px: 1, // 横のスペースを少し増やす
            pb: 1, // 下のスペースも追加
            pt: 1,
          }}
        >
          <Grid container spacing={1.4} alignItems={"stretch"}>
            {genres.map((genre, index) => (
              <Grid item xs={6} sm={4} md={4} key={index}>
                {" "}
                {/* 横2列にするため xs={6} に変更 */}
                <Card
                  onClick={() => handleGenreClick(genre.name)}
                  sx={{
                    border: selectedGenres.includes(genre.name)
                      ? "3px solid rgba(99, 180, 255)" // 選択時は薄めの水色
                      : "2px solid rgba(204, 204, 204, 0.6)", // グレー枠をさらに薄く
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    borderRadius: "12px",
                    height: "148px",
                    flex: 1,
                    boxShadow: selectedGenres.includes(genre.name)
                      ? "0px 8px 16px rgba(99, 180, 255, 0.3)" // 選択時の影
                      : "0px 4px 12px rgba(0, 0, 0, 0.1)", // 通常時の影
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out", // モダンなトランジション
                    "&:hover": {
                      transform: "scale(1.05)", // ホバー時に少し拡大
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)", // 影を少し強く
                    },
                    "&:focus": {
                      outline: "none", // フォーカス時の枠を無効化
                    },
                    "&:active": {
                      transform: "scale(1)", // クリック時に元に戻す
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // クリック時の影
                    },
                  }}
                >
                  <CardActionArea
                    sx={{
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    <Box sx={{ position: "relative", height: "66%" }}>
                      <CardMedia
                        component="img"
                        height="100%"
                        image={genre.image}
                        alt={genre.name}
                        sx={{
                          objectFit: "cover",
                          height: {
                            xs: "100px",
                            md: "100px",
                          },
                        }}
                      />
                      <Chip
                        label={genre.name}
                        sx={{
                          position: "absolute",
                          bottom: {
                            xs: 2,
                            md: 2,
                          },
                          left: 4,
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          color: "white",
                          fontSize: "0.8rem",
                          padding: "0 2px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          zIndex: 2,
                        }}
                      />
                    </Box>
                    <CardContent
                      sx={{
                        padding: "8px",
                        height: "36%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        sx={{
                          fontSize: "0.8rem",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {genre.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          disabled={loading}
          sx={{
            border: "1px solid #ccc",
            backgroundColor: "white",
            color: "#333",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          閉じる
        </Button>
        <Button
          onClick={handleAdd}
          sx={{
            backgroundColor: isSaveDisabled ? "#ccc" : "#007BFF", // 押せる時のモダンな青色
            color: "white",
            "&:hover": {
              backgroundColor: isSaveDisabled ? "#ccc" : "#0056b3", // ホバー時の青色
            },
          }}
        >
          {loading ? <CircularProgress size={24} /> : "保存"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenreSelectionDialog;
