import React from "react";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TopPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
        padding: "1rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Card
        sx={{
          maxWidth: "400px",
          width: "100%",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              marginTop: "3rem", // 統一された位置
              marginBottom: "2rem",
              fontSize: "1.8rem",
            }}
          >
            Gather Data App
          </Typography>
          <img
            src="/images/top_image.png"
            alt="食事履歴"
            style={{
              maxWidth: "100%",
              height: "auto",
              marginBottom: "1rem",
              borderRadius: "8px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "72%",
              marginTop: "2rem",
              marginBottom: "4rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ marginBottom: "0.8rem", fontSize: "1rem" }}
              onClick={() => navigate("/login")}
            >
              ログイン
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => navigate("/signup")}
              sx={{ fontSize: "1rem" }}
            >
              新規登録
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TopPage;
