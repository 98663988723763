import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import MealHistoryPage from "./pages/MealHistoryPage";
import RatingListPage from "./pages/RatingListPage";
import TopPage from "./pages/TopPage";
import { GlobalStyles } from "./styles/GlobalStyles";
import { ResponsiveStyles } from "./styles/ResponsiveStyles";
import { Global } from "@emotion/react";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import AuthRoute from "./components/AuthRoute";

const App: React.FC = () => {
  return (
    <>
      <Global styles={ResponsiveStyles} />
      <Global styles={GlobalStyles} />
      <Router>
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              element={
                <AuthRoute>
                  <TopPage />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <AuthRoute>
                  <LoginPage />
                </AuthRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <AuthRoute>
                  <SignUpPage />
                </AuthRoute>
              }
            />
            <Route
              path="/meal-history"
              element={
                <PrivateRoute>
                  <MealHistoryPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/rating-list"
              element={
                <PrivateRoute>
                  <RatingListPage />
                </PrivateRoute>
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </>
  );
};

export default App;
