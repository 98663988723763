import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { login } from "../services/api";
import { useSnackbar } from "notistack";
import { useAuth } from "../contexts/AuthContext"; // useAuth を使用

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { login: loginAuth } = useAuth(); // useAuth から login を取得

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const data = await login(email, password);
    if (data.success) {
      enqueueSnackbar("ログイン成功！", { variant: "success" });
      loginAuth(data.token, data.username, data.userId); // ログイン情報をコンテキストに保存
      navigate("/meal-history");
    } else {
      enqueueSnackbar(`ログイン失敗: ${data.message}`, { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
        padding: "1rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Card
        sx={{
          maxWidth: "400px",
          width: "100%",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
          position: "relative",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "2.5rem",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ marginTop: "3rem", fontWeight: "Bold" }}
          >
            ログイン画面
          </Typography>
          <Divider
            sx={{ width: "70%", marginY: "0.4rem", borderColor: "#D9D9D9" }}
          />
          <CardContent
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
            }}
            onSubmit={handleLogin}
          >
            <Typography variant="subtitle1">メールアドレス</Typography>
            <TextField
              label=""
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              sx={{ marginTop: "0.4rem", marginBottom: " 0.6rem" }}
            />
            <Typography variant="subtitle1" sx={{ marginTop: "0.4rem" }}>
              パスワード
            </Typography>
            <TextField
              label=""
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              sx={{ marginTop: "0.4rem", marginBottom: " 1rem" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: "0.4rem",
                marginBottom: "1rem",
                fontSize: "1rem",
              }}
            >
              ログイン
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIcon />}
              sx={{
                marginTop: "0.4rem",
                marginBottom: "1rem",
                backgroundColor: "#fff",
                borderColor: "#ccc",
                color: "#555",
                width: "40%",
                fontSize: "0.8rem",
              }}
            >
              戻る
            </Button>
          </CardContent>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginPage;
