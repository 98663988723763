import React, { useState } from "react";
import {
  Avatar,
  Box,
  Typography,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import PersonIcon from "@mui/icons-material/Person"; // デフォルトアイコンとしてPersonアイコンを使用

const AccountInfo: React.FC = () => {
  const { logout, username } = useAuth(); // username を useAuth から取得
  const [open, setOpen] = useState(false);

  // ダイアログの開閉
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{ padding: "1rem 1rem 1rem 0.6rem" }}
      >
        <Paper
          elevation={1}
          sx={{
            display: "flex",
            alignItems: "center",
            borderRadius: "30px",
            padding: "0.2rem 0.8rem 0.2rem 0.2rem",
            cursor: "pointer",
            boxShadow: "0 1px 3px rgba(99, 180, 255, 0.05)",
            backgroundColor: "rgba(99, 180, 255, 0.1)",
            color: "rgba(99, 180, 255)",
          }}
          onClick={handleOpen}
        >
          <Avatar
            sx={{
              width: 28,
              height: 28,
              fontSize: 14,
              backgroundColor: username ? "white" : "#ccc",
              color: username ? "#63b4ff" : "white",
            }}
          >
            {username ? (
              username[0].toUpperCase()
            ) : (
              <PersonIcon sx={{ width: 24, height: 24 }} />
            )}
          </Avatar>
          <Typography
            variant="subtitle2"
            sx={{
              ml: 0.6,
              maxWidth: "80px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {username || "Guest"}
          </Typography>
        </Paper>
      </Box>

      {/* ダイアログの内容 */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>アカウント</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mb={2}>
            <Avatar sx={{ width: 40, height: 40, mr: 2, fontSize: 18 }}>
              {username ? (
                username[0].toUpperCase()
              ) : (
                <PersonIcon sx={{ width: 32, height: 32 }} />
              )}
            </Avatar>
            <Typography variant="h6">{username || "Guest"}</Typography>
          </Box>
          <Typography>ログアウトしますか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={() => {
              logout();
              handleClose();
            }}
            color="primary"
          >
            ログアウト
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AccountInfo;
