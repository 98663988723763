export const addRating = async (
  hungerLevel: number,
  genres: string[],
  time: string,
  userId: string
) => {
  try {
    const response = await fetch(
      "https://gourmet-recommender.noise06kmy69.workers.dev/rating/add",
      {
        // 修正箇所
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hungerLevel, genres, time, userId }),
      }
    );

    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || "評価の追加に失敗しました");
    }
  } catch (error: any) {
    console.error(error);
    return { success: false, message: error.message };
  }
};

export const fetchUserRatings = async (userId: string) => {
  try {
    const response = await fetch(
      `https://gourmet-recommender.noise06kmy69.workers.dev/rating/list/${userId}`
    );
    const data = await response.json();
    if (response.ok && Array.isArray(data)) {
      return { success: true, ratings: data };
    } else {
      throw new Error(data.message || "評価履歴の取得に失敗しました");
    }
  } catch (error: any) {
    console.error(error);
    return { success: false, message: error.message };
  }
};

export const deleteRating = async (ratingId: string, userId: string) => {
  const response = await fetch(
    `https://gourmet-recommender.noise06kmy69.workers.dev/rating/delete?ratingId=${ratingId}&userId=${userId}`,
    {
      method: "DELETE",
    }
  );

  if (!response.ok) {
    throw new Error("評価履歴の削除に失敗しました");
  }

  return await response.json();
};
