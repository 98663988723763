import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { signUp } from "../services/api";
import { useSnackbar } from "notistack"; // スナックバー用フックをインポート

const SignUpPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    return password.length >= 4;
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      enqueueSnackbar("無効なメールアドレスです", { variant: "error" });
      return;
    }

    if (!validatePassword(password)) {
      enqueueSnackbar("パスワードは4文字以上である必要があります", {
        variant: "error",
      });
      return;
    }

    const data = await signUp(name, email, password);
    if (data.success) {
      enqueueSnackbar("新規登録成功！", { variant: "success" });
      navigate("/login"); // 自動でログインさせず、ログインページに遷移
    } else {
      enqueueSnackbar(`新規登録失敗: ${data.message}`, { variant: "error" });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 4rem)",
        padding: "1rem",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Card
        sx={{
          maxWidth: "400px",
          width: "100%",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
          position: "relative",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: "2.5rem",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ marginTop: "3rem", fontWeight: "Bold" }}
          >
            新規登録
          </Typography>
          <Divider
            sx={{ width: "70%", marginY: "0.4rem", borderColor: "#D9D9D9" }}
          />
          <CardContent
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "80%",
              marginTop: "0.4rem",
            }}
            onSubmit={handleSignUp}
          >
            <Typography variant="subtitle1">名前</Typography>
            <TextField
              label=""
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              sx={{ marginTop: "0.4rem", marginBottom: " 0.6rem" }}
            />
            <Typography variant="subtitle1">メールアドレス</Typography>
            <TextField
              label=""
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              sx={{ marginTop: "0.4rem", marginBottom: " 0.6rem" }}
            />
            <Typography variant="subtitle1">パスワード (4文字以上)</Typography>
            <TextField
              label=""
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              sx={{ marginTop: "0.4rem", marginBottom: " 1rem" }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                marginTop: "0.4rem",
                marginBottom: "1rem",
                fontSize: "1rem",
              }}
            >
              新規登録
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => navigate("/")}
              startIcon={<ArrowBackIcon />}
              sx={{
                marginTop: "0.4rem",
                marginBottom: "1rem",
                backgroundColor: "#fff",
                borderColor: "#ccc",
                color: "#555",
                width: "40%",
                fontSize: "0.8rem",
              }}
            >
              戻る
            </Button>
          </CardContent>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SignUpPage;
