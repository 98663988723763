import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const AuthRoute = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <Navigate to="/meal-history" /> : children;
};

export default AuthRoute;
