import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  Divider,
  Fab,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import TabBar from "../components/TabBar"; // タブバーコンポーネント
import GenreSelectionDialog from "../components/GenreSelectionDialog"; // ダイアログコンポーネント
import { useSnackbar } from "notistack";
import { fetchUserRatings, deleteRating } from "../services/ratingService";
import { useAuth } from "../contexts/AuthContext";
import DeleteConfirmationDialog from "../components/DeleteConfirmDialog";
import TitleBar from "../components/TitleBar";

interface RatingHistory {
  id: string;
  hunger_level: number;
  genres: string;
  created_at: string;
}

const RatingListPage: React.FC = () => {
  const [ratings, setRatings] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedRating, setSelectedRating] = useState<RatingHistory | null>(
    null
  );
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = useAuth();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ログインしているユーザーの評価を取得
  useEffect(() => {
    const fetchRatings = async () => {
      try {
        if (userId) {
          const response = await fetchUserRatings(userId);
          if (response.success) {
            setRatings(response.ratings || []);
          } else {
            enqueueSnackbar("評価履歴の取得に失敗しました", {
              variant: "error",
            });
          }
        }
      } catch (error) {
        enqueueSnackbar("評価履歴の取得に失敗しました", { variant: "error" });
        console.error(error);
      }
    };
    if (userId) {
      fetchRatings();
    }
  }, [userId, enqueueSnackbar]);

  // 削除ダイアログを開く関数
  const handleDeleteClick = (rating: RatingHistory) => {
    setSelectedRating(rating);
    setDeleteOpen(true);
  };

  // 削除ダイアログを閉じる関数
  const handleDeleteClose = () => {
    setSelectedRating(null);
    setDeleteOpen(false);
  };

  // 削除の確定処理
  const handleDeleteConfirm = async () => {
    if (selectedRating && userId) {
      try {
        await deleteRating(selectedRating.id, userId);
        setRatings(ratings.filter((rating) => rating.id !== selectedRating.id));
        enqueueSnackbar("削除が成功しました", { variant: "success" });
        setDeleteOpen(false);
      } catch (error) {
        enqueueSnackbar("削除に失敗しました", { variant: "error" });
        console.error("削除に失敗しました:", error);
      }
    }
  };

  return (
    <Box sx={{ pb: 8 }}>
      {/* TitleBar コンポーネント */}
      <TitleBar title="評価履歴" />
      {/* 評価カードを表示 */}
      {ratings.length === 0 ? (
        <Typography variant="body2" sx={{ textAlign: "center", mt: 2 }}>
          評価履歴がありません
        </Typography>
      ) : (
        ratings.map((rating, index) => (
          <Card
            sx={{
              position: "relative",
              borderRadius: "24px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
              ml: 2,
              mr: 2,
              mt: 2,
              mb: 2,
            }}
          >
            <IconButton
              onClick={() => handleDeleteClick(rating)}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "white",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                },
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
            >
              <DeleteIcon sx={{ color: "rgba(99, 180, 255)" }} />
            </IconButton>
            <CardContent>
              {/* 時間表示 */}
              <Typography sx={{ fontSize: "1.2rem", fontWeight: 600 }}>
                {new Date(rating.created_at).toLocaleString()}{" "}
                {/* 例: 2024年10月5日 12:34 */}
              </Typography>
              <Divider sx={{ my: 1 }} /> {/* グレーの線 */}
              {/* 空腹度 */}
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                空腹度
              </Typography>
              <Chip
                label={
                  rating.hunger_level === 1
                    ? "小"
                    : rating.hunger_level === 2
                    ? "中"
                    : "大"
                }
                sx={{
                  mt: 1,
                  backgroundColor: "rgba(99, 180, 255, 0.1)",
                  color: "rgba(99, 180, 255)",
                  "&:hover": {
                    backgroundColor: "rgba(99, 180, 255, 0.4)",
                  },
                }}
              />
              {/* 食べたいジャンル */}
              <Typography variant="body2" sx={{ fontWeight: "bold", mt: 2 }}>
                食べたいジャンル
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                {JSON.parse(rating.genres).map((genre: string, i: number) => (
                  <Chip
                    key={i}
                    label={genre}
                    sx={{
                      backgroundColor: "rgba(99, 180, 255, 0.1)",
                      color: "rgba(99, 180, 255)",
                      "&:hover": {
                        backgroundColor: "rgba(99, 180, 255, 0.4)",
                      },
                    }}
                  />
                ))}
              </Box>
            </CardContent>
          </Card>
        ))
      )}

      {/* 右下の追加ボタン */}
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: 88, right: 16 }} // タブバーの上に配置
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>

      {/* タブバー */}
      <TabBar />

      {/* ダイアログ */}
      <GenreSelectionDialog open={open} onClose={handleClose} />
      {/* 削除ダイアログ */}
      <DeleteConfirmationDialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        itemName={
          selectedRating
            ? `${new Date(selectedRating.created_at).toLocaleString()} の評価`
            : "評価履歴"
        }
      />
    </Box>
  );
};

export default RatingListPage;
