import { css } from "@emotion/react";

export const ResponsiveStyles = css`
  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }

    .card {
      width: 90%;
      margin: 0 auto;
    }
  }

  @media (min-width: 769px) {
    .card {
      width: 400px;
      margin: 20px auto;
    }
  }
`;
